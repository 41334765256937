import {
  COLUMN_TYPE_ACCOUNT_ROLES,
  COLUMN_TYPE_FILES_CATEGORY,
  COLUMN_TYPE_FILES_DATE_AND_SOURCE,
  COLUMN_TYPE_FILES_FILE_NAME,
  COLUMN_TYPE_FILES_SHARED_WITH,
  COLUMN_TYPE_FILES_UNAVAILABLE_REASON,
} from "core/consts";
import { getDateValue, getLocale } from "core/model/utils/dates";
import {
  AcceptedTableRequest,
  Auction,
  Careprovider,
  ContactedTableRequest,
  DeclinedTableRequest,
  FileCategory,
  FileHISStateful,
  Filev2,
  PotentialReceiversTableCandidate,
  RejectedTableRequest,
  RequestChipStatus,
  Roles,
  SearchTable,
  TourElement,
  ValidatedTableRequest,
} from "core/types";
import Tooltip from "ds_legacy/components/Tooltip";
import { GREY_500 } from "ds_legacy/materials/colors";
import { BodyProps } from "ds_legacy/materials/typography";
import { RequestListType } from "dsl/ecosystems/PatientSearchMerge/Components/SearchPageTables/Tables/PotentialReceiverTable/PotentialReceiverTableReducer";
import { useHasOverFlown } from "dsl/hooks";
import { blurStyle } from "dsl/organisms/Infos/PatientInformation/shared";
import { FunctionComponent } from "react";
import { useLocale } from "translations";
import { DefaultCellBodyText } from "../styles";
import { CareseekerAccountRolesChip } from "./AccountTable";
import {
  CategoryValue,
  DateAndSource,
  FileName,
  SharedWithValue,
  UnavailableReason,
} from "./Files";
import {
  AddColumnIndent,
  COLUMN_TYPE_SEARCH_TABLE_CHECKBOX,
  COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY,
  COLUMN_TYPE_SEARCH_TABLE_INDENT,
  COLUMN_TYPE_SEARCH_TABLE_NAME_WITH_ICONS,
  COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT,
  COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS,
  COLUMN_TYPE_SEARCH_TABLE_STATUS,
  ContactAnywayAction,
  ProviderNameWithIcons,
  SelectProviderCheckbox,
  StatusValue,
} from "./SearchTables";
import {
  PreferredIconInput,
  PreferredIconInputPotentalReceiver,
} from "./SearchTables/PatientPreferredCell";

/**
 * A cell value is rendered as a plain string (see: DefaultValue), however, can be customized using your own custom component.
 * Here's how you can customize a cell value for a specific column.
 *
 * Let's say you want to display table of cats.
 *
 * type Cat = {
 *   id: number;
 *   name: string;
 *   weight: number;
 * }
 *
 * But for the cat's weight, rather than just showing a plain string, you want to show an icon, if the cat's weight exceeds 100kg
 *
 * This would not be possible without a custom component, as the default component just shows the value as a string.
 * So here's how you can define a custom component.
 *
 * Step 1: Define you custom component (if it's only specific to a feature (see Files or SearchTables), create a new tsx.file)
 *
 * export function MyCustomValue({ weight }: Cat['weight']){
 *    if(weight > 100){
 *      return <div><Icon />{" "}{weight}</div>
 *    }
 *    return <>{weight}</>
 * }
 *
 * Step 2: Add a new column type
 *
 * export const COLUMN_TYPE_CAT_WEIGHT
 * export const COLUMN_TYPES = [
 *
 *  ...
 *
 *  COLUMN_TYPE_CAT_WEIGHT
 * ];
 *
 * Step 3: Add your custom component condition to the getCellComponent
 * export function getCellComponent({ type }: { type: ColumnKinds }) {
 *   switch (type) {
 *
 *     ...
 *
 *     case COLUMN_TYPE_CAT_WEIGHT:
 *       return MyCustomValue;
 *
 *     ...
 *   }
}
 * Step 4: Using it in the table
 * <CatTable
    data={data as Cat} // casting the type here only for demo purpose
    columns={[
      {
        getProps: ({ name }) => ({ value: name }),
        title: "Name"
      },
      {
        getProps: "weight",
        title: "Weight",
        type: COLUMN_TYPE_CAT_WEIGHT,
      },
      ...
 *
 */

// Non-specific column types
export const COLUMN_TYPE_DEFAULT_ACTION = "action" as const;
export const COLUMN_TYPE_DEFAULT_STRING = "string" as const;
export const COLUMN_TYPE_DEFAULT_DATE = "date" as const;
export const COLUMN_TYPE_DEFAULT_TOOLTIP = "tooltip" as const;

const NON_SPECIFIC_COLUMN_TYPES = [
  COLUMN_TYPE_DEFAULT_ACTION,
  COLUMN_TYPE_DEFAULT_STRING,
  COLUMN_TYPE_DEFAULT_DATE,
  COLUMN_TYPE_DEFAULT_TOOLTIP,
] as const;

export const COLUMN_TYPES = [
  COLUMN_TYPE_ACCOUNT_ROLES,
  COLUMN_TYPE_DEFAULT_DATE,
  COLUMN_TYPE_DEFAULT_STRING,
  COLUMN_TYPE_DEFAULT_TOOLTIP,
  COLUMN_TYPE_FILES_CATEGORY,
  COLUMN_TYPE_FILES_DATE_AND_SOURCE,
  COLUMN_TYPE_FILES_FILE_NAME,
  COLUMN_TYPE_FILES_SHARED_WITH,
  COLUMN_TYPE_FILES_UNAVAILABLE_REASON,
  COLUMN_TYPE_SEARCH_TABLE_CHECKBOX,
  COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY,
  COLUMN_TYPE_SEARCH_TABLE_INDENT,
  COLUMN_TYPE_SEARCH_TABLE_NAME_WITH_ICONS,
  COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS,
  COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT,
  COLUMN_TYPE_SEARCH_TABLE_STATUS,
] as const;

export type ColumnKinds = (typeof COLUMN_TYPES)[number];
type NonSpecificColumnKinds = (typeof NON_SPECIFIC_COLUMN_TYPES)[number];
export type AllColumnKinds = ColumnKinds | NonSpecificColumnKinds;

type LinkedColumnCellProps = {
  [COLUMN_TYPE_ACCOUNT_ROLES]: {
    value: Roles | undefined;
  };
  [COLUMN_TYPE_DEFAULT_DATE]: {
    value: number;
    withTime?: boolean;
  };
  [COLUMN_TYPE_DEFAULT_STRING]: {
    disabled?: boolean;
    testId?: string;
    value: string | undefined;
  };
  [COLUMN_TYPE_DEFAULT_TOOLTIP]: {
    shouldBlur?: boolean;
    value: unknown;
  };
  [COLUMN_TYPE_FILES_CATEGORY]: { category: FileCategory };
  [COLUMN_TYPE_FILES_DATE_AND_SOURCE]: { source: string; uploadDate: number };
  [COLUMN_TYPE_FILES_FILE_NAME]: {
    isNewVersionAvailable?: boolean;
    shouldBlur?: boolean;
    value: unknown;
  };
  [COLUMN_TYPE_FILES_SHARED_WITH]: {
    careseekerId: number | null;
    share_mode: Filev2["share_mode"];
  };
  [COLUMN_TYPE_FILES_SHARED_WITH]: {
    careseekerId: number | null;
    share_mode: Filev2["share_mode"];
  };
  [COLUMN_TYPE_FILES_UNAVAILABLE_REASON]: {
    unavailable_reason: FileHISStateful["unavailable_reason"];
  };
  [COLUMN_TYPE_SEARCH_TABLE_CHECKBOX]: {
    disabled: boolean;
    value: RequestListType;
  };
  [COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY]: {
    auction: Auction;
    careprovider: Careprovider;
    recommendationId: number | undefined;
    refresh: () => void;
    sendFax?: boolean;
  };
  [COLUMN_TYPE_SEARCH_TABLE_INDENT]: Record<string, never>;
  [COLUMN_TYPE_SEARCH_TABLE_NAME_WITH_ICONS]: {
    name: string | undefined;
    patient_data_shared: boolean;
    testId?: string;
  };
  [COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT]: {
    auction: Auction;
    auctionRequest:
      | AcceptedTableRequest
      | ContactedTableRequest
      | DeclinedTableRequest
      | RejectedTableRequest
      | ValidatedTableRequest;
    invalidate?: () => void;
    refresh?: () => void;
    table: SearchTable;
  };
  [COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS]: {
    auction: Auction;
    auctionRequest: PotentialReceiversTableCandidate;
    table: SearchTable;
  };
  [COLUMN_TYPE_SEARCH_TABLE_STATUS]: { value: RequestChipStatus };
};

export type CellProps<Kind extends ColumnKinds> = LinkedColumnCellProps[Kind];

export type GeneralTableColumn<Data, Kind extends ColumnKinds> = {
  align?: "left" | "right";
  disableDivider?: boolean;
  getProps:
    | keyof Data
    | ((data: Data) => CellProps<Kind> | Promise<CellProps<Kind>>);
  hint?: string;
  isHidden?: boolean;
  sortBy?: string;
  title?: JSX.Element | string;
  tour?: TourElement;
  type?: Kind;
  width?: number | string;
};

export function getCellComponent({
  type,
}: {
  type: ColumnKinds;
}): FunctionComponent<any> {
  switch (type) {
    case COLUMN_TYPE_ACCOUNT_ROLES:
      return CareseekerAccountRolesChip;
    case COLUMN_TYPE_DEFAULT_DATE:
      return DateValue;
    case COLUMN_TYPE_DEFAULT_STRING:
      return StringCell;
    case COLUMN_TYPE_DEFAULT_TOOLTIP:
      return ValueWithToolTip;
    case COLUMN_TYPE_FILES_CATEGORY:
      return CategoryValue;
    case COLUMN_TYPE_FILES_FILE_NAME:
      return FileName;
    case COLUMN_TYPE_FILES_DATE_AND_SOURCE:
      return DateAndSource;
    case COLUMN_TYPE_FILES_SHARED_WITH:
      return SharedWithValue;
    case COLUMN_TYPE_FILES_UNAVAILABLE_REASON:
      return UnavailableReason;
    case COLUMN_TYPE_SEARCH_TABLE_CHECKBOX:
      return SelectProviderCheckbox;
    case COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY:
      return ContactAnywayAction;
    case COLUMN_TYPE_SEARCH_TABLE_INDENT:
      return AddColumnIndent;
    case COLUMN_TYPE_SEARCH_TABLE_NAME_WITH_ICONS:
      return ProviderNameWithIcons;
    case COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS:
      return PreferredIconInputPotentalReceiver;
    case COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT:
      return PreferredIconInput;
    case COLUMN_TYPE_SEARCH_TABLE_STATUS:
      return StatusValue;

    default:
      return DefaultValue;
  }
}

export function DateValue({
  value,
  withTime,
}: CellProps<typeof COLUMN_TYPE_DEFAULT_DATE>) {
  const locale = useLocale();
  const currentLocale = getLocale(locale);
  const formattedValue = getDateValue(value, currentLocale, withTime);

  return <DefaultTextCell message={formattedValue} />;
}

export function ValueWithToolTip({
  shouldBlur,
  value,
}: CellProps<typeof COLUMN_TYPE_DEFAULT_TOOLTIP>) {
  const formattedValue = getDefaultValue(value);

  return (
    <Tooltip title={formattedValue}>
      <DefaultCellBodyText style={{ ...(shouldBlur ? blurStyle : {}) }}>
        {formattedValue}
      </DefaultCellBodyText>
    </Tooltip>
  );
}

export function getDefaultValue(value: unknown): string {
  if (Array.isArray(value)) {
    return value.length === 0 ? "None" : value.join(", ");
  }
  return String(value);
}

export function DefaultValue({ value }: { value: unknown }) {
  const formattedValue = getDefaultValue(value);
  return <DefaultCellBodyText>{formattedValue}</DefaultCellBodyText>;
}

export const DefaultTextCell = ({
  disabled,
  message,
  onClick,
  testId,
  ...bodyProps
}: BodyProps & {
  disabled?: boolean;
  message: string | undefined;
  onClick?: () => void;
  testId?: string;
}) => {
  const { hasOverFlown, ref } = useHasOverFlown<HTMLSpanElement>({
    direction: "y",
    value: message,
  });

  return (
    <Tooltip title={hasOverFlown ? message : ""}>
      <DefaultCellBodyText
        data-testid={testId}
        {...bodyProps}
        color={disabled ? GREY_500 : bodyProps.color}
        onClick={onClick}
        ref={ref}
      >
        {message}
      </DefaultCellBodyText>
    </Tooltip>
  );
};

export const StringCell = ({
  disabled,
  testId,
  value,
}: CellProps<typeof COLUMN_TYPE_DEFAULT_STRING>) => {
  return (
    <DefaultTextCell
      testId={testId}
      message={value ?? ""}
      disabled={disabled}
    />
  );
};
